import { Conversation } from "../../models/messages";
import { ActionType } from "../action-types";
import { Action } from "../actions";

const initialState: MessageState = {
  conversations: [],

};

export interface MessageState {
  conversations?: Conversation[];
}

const messageReducer = (state: MessageState = initialState, action: Action): MessageState => {
  switch (action.type) {
    case ActionType.MESSAGE_ADD:
      return { ...state, ...action.payload };
    case ActionType.MESSAGE_CLEAR:
      return initialState;
    default:
      return state;
  }
};

export default messageReducer;
