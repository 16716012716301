import { LocationData } from "../../models/LocationData";
import { ActionType } from "../action-types";
import { Action } from "../actions";

const initialState: activeDataState = {
  usePositions: [],
};

export interface activeDataState {
  usePositions: LocationData[];
}

const activeDataReducer = (state: activeDataState = initialState, action: Action): activeDataState => {
  switch (action.type) {
    case ActionType.DATA_ADD:
      return { ...state, usePositions: action.payload.usePositions };
    case ActionType.DATA_CLEAR:
      return initialState;
    default:
      return state;
  }
};

export default activeDataReducer;
