import { AuthResult } from '../../models/AuthResult';
import { ActionType } from '../action-types';
import { Action } from '../actions';

const initialState: AuthState | null = null;

export interface AuthState {
  auth: AuthResult;
}

const authReducer = (state: AuthState | null = initialState, action: Action): AuthState | null => {
  switch (action.type) {
    case ActionType.AUTH_ADD:
      return {...state, ...action.payload};
    case ActionType.AUTH_CLEAR:
      return initialState;
    default:
      return state;
  }
};

export default authReducer;
