


export interface ICosSummary {
    itemCount: number;
    kolliCount: number;
    weight: number;
    prisedWeight: number;
    cost: number;
}


export const calculateCostSummary = (items: any[]): ICosSummary => {

    let itemCount: number = items.length;
    let kolliCount: number = 0;
    let prisedWeight: number = 0;
    let weight: number = 0;
    let cost: number = 0;

    items.forEach((item) => {
        kolliCount += parseFloat(item.kolliantal || "0.0");
        weight += parseFloat(item.ordervikt || "0.0");
        // cost += item.cost;
    });
    

    return { itemCount, kolliCount, weight, prisedWeight, cost };
}