import { calculateCostSummary } from "../../../helpers/CostSummary";
import CostDisplay from "../../costDisplay";
import { default as CreatedDirectPickupTable, default as CreatePickupTable } from "./createdDirectPickupTable";
import PickupTable from "./pickuptable";

import "../../../styles/styles.css";


export interface IPickupsDirectProps {
    createdNormal: any[] | undefined;
    createdDirect: any[] | undefined;
    distributed: any[] | undefined;
    loaded: any[] | undefined;
    selectedItem: string | undefined;
    filters: string[];
    onTap: (item: any) => void;
}

const PickupsTables = (props: IPickupsDirectProps) => {

    const createdNormalStats = calculateCostSummary(props.createdNormal || []);
    const createdDirectStats = calculateCostSummary(props.createdDirect || []);
    const distributedStats = calculateCostSummary(props.distributed || []);
    const loadedStats = calculateCostSummary(props.loaded || []);

    return (
        <div className="orderTable" style={{ border: "solid 0.0625rem black", padding: 5 }}>
            <div className="row" style={{ alignItems: 'flex-start' }}>
                <div className="column">
                    <div className="expand">
                        <div className="spacedRow" style={{ padding: "0px" }}>
                            <div className="sectionTitle">Skapade hämtningar</div>
                            <CostDisplay summary={createdNormalStats} />
                        </div>
                        <CreatePickupTable items={props.createdNormal} selectedItem={props.selectedItem} onTap={props.onTap} filters={props.filters} ></CreatePickupTable>
                    </div>
                    <div className="expand">
                        <div className="spacedRow" style={{ padding: "0px" }}>
                            <div className="sectionTitle">Skapade Direktkröningar</div>
                            <CostDisplay summary={createdDirectStats} />
                        </div>
                        <CreatedDirectPickupTable items={props.createdDirect} selectedItem={props.selectedItem} onTap={props.onTap} filters={props.filters} ></CreatedDirectPickupTable>
                    </div>
                </div>
                <div className="column">
                    <div className="expand">
                        <div className="spacedRow" style={{ padding: "0px" }}>
                            <div className="sectionTitle">Fördelade</div>
                            <CostDisplay summary={distributedStats} />
                        </div>
                        <PickupTable items={props.distributed} selectedItem={props.selectedItem} onTap={props.onTap} filters={props.filters} ></PickupTable>
                    </div>
                    <div className="expand">
                        <div className="spacedRow" style={{ padding: "0px" }}>
                            <div className="sectionTitle">Lastade</div>
                            <CostDisplay summary={loadedStats}/>
                        </div>
                        <PickupTable items={props.loaded} selectedItem={props.selectedItem} onTap={props.onTap} filters={props.filters} ></PickupTable>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default PickupsTables;