import toastr from "toastr";
import "toastr/build/toastr.min.css";

export interface ISnackBar {
  success(
    message: string,
    title?: string | undefined,
    options?: ToastrOptions
  ): void;
  warning(
    message: string,
    title?: string | undefined,
    options?: ToastrOptions
  ): void;
  error(
    message: string[] | string,
    title: string,
    options?: ToastrOptions
  ): void;
  info(
    message: string,
    title?: string | undefined,
    options?: ToastrOptions
  ): void;
  clear(): void;
}

interface ToastrOptions {
  positionClass?: "toast-bottom-right" | "toast-bottom-center" | "toast-center";
  hideDuration?: number;
  timeOut?: number;
  closeButton?: boolean;
  extendedTimeOut?: number;
}

const defaultToastrOptions: ToastrOptions = {
  positionClass: "toast-bottom-right",
  hideDuration: 200,
  timeOut: 5000,
  closeButton: true,
  extendedTimeOut: 5000,
};

const errorOptions: ToastrOptions = {
  positionClass: "toast-center",
  hideDuration: 200,
  timeOut: 60000,
  closeButton: true,
  extendedTimeOut: 60000,
};

const messageToList = (message: string[]) => {
  return "<UL>" + message.map((x) => "<LI>" + x + "</LI>").join("") + "</UL>";
};

const snackbar: ISnackBar = {
  success: function (
    message: string,
    title?: string | undefined,
    options = defaultToastrOptions
  ) {
    return toastr.success(message, title, options);
  },
  warning: function (
    message: string,
    title?: string | undefined,
    options = defaultToastrOptions
  ) {
    return toastr.warning(message, title, options);
  },
  error: function (
    message: string[] | string,
    title: string,
    options: ToastrOptions = defaultToastrOptions
  ) {
    const _options = { ...errorOptions, ...options };
    return toastr.error(
      Array.isArray(message) ? messageToList(message) : message,
      title,
      _options
    );
  },
  info: function (
    message: string,
    title?: string | undefined,
    options = defaultToastrOptions
  ) {
    return toastr.info(message, title, options);
  },
  clear: function () {
    return toastr.clear();
  },
};

export default snackbar;
