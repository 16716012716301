import { calculateCostSummary } from "../../../helpers/CostSummary";
import CostDisplay from "../../costDisplay";
import DeliveredTable from "./deliveredTable";



interface IDeliveredProps {
    items: any[] | undefined;
    selectedItem: string | undefined;
    filters: string[];
    onSingleTap: (item: any) => void;
}


const Delivered = (props: IDeliveredProps) => {

    const createdStats = calculateCostSummary(props.items || []);

    return (
        <div style={{ border: "solid 0.0625rem black", padding: 5 }}>
            <div className="column">
                <div className="expand doubleExpanded">
                    <div className="spacedRow" style={{ padding: "0px" }}>
                        <div className="sectionTitle">Alla</div>
                        <CostDisplay summary={createdStats} />
                    </div>
                    <DeliveredTable items={props.items} selectedItem={props.selectedItem} onSingleTap={props.onSingleTap} filters={props.filters} ></DeliveredTable>
                </div>
            </div>
        </div>
    )
}


export default Delivered;