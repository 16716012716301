import { calculateCostSummary } from "../../../helpers/CostSummary";
import CostDisplay from "../../costDisplay";
import AllStatusTable from "./AllStatusTable";



interface IAllStatusProps {
    items: any[] | undefined;
    selectedItem: string | undefined;
    filters: string[];
    onRightClick: (item: any) => void;
}


const AllStatus = (props: IAllStatusProps) => {

    const createdStats = calculateCostSummary(props.items || []);

    return (
        <div style={{ border: "solid 0.0625rem black", padding: 5 }}>
            <div className="column">
                <div className="expand">
                    <div className="spacedRow" style={{ padding: "0px" }}>
                        <div className="sectionTitle">Alla statusar</div>
                        <CostDisplay summary={createdStats} />
                    </div>
                    <AllStatusTable selectedItem={props.selectedItem} items={props.items} onRightClick={props.onRightClick} filters={props.filters}></AllStatusTable>
                </div>
            </div>
        </div>
    )
}


export default AllStatus;