import {
    DefaultButton,
    Stack,
    Text,
    TextField,
} from "@fluentui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import StorageKeys from "../helpers/StorageKeys";
import snackbar from "../helpers/toastrHelper";
import { login } from "../Services/authService";
import { actionCreators } from "../state";
import { store } from "../state/store";

export const LogInPage = () => {
    const navigate = useNavigate();

    const addAuth = bindActionCreators(actionCreators, store.dispatch).addAuth;

    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");

    const handleLogin = async () => {
        try {
            const response = await login(username, password);

            console.log("result", response);

            if (!response.success || !response.result) { 
                snackbar.error("Fel användarnamn eller lösenord", "Fel inloggining");
                return;
            }

            if (response.success) {
                addAuth({ auth: response.result });
                localStorage.setItem(StorageKeys.user, JSON.stringify(response.result));

                navigate("/Main");
            }

        } catch (e) {
            console.log(e);
        }
    };

    return (
        <div
            style={{
                height: "100vh",
                width: "100vw",
                display: "grid",
                gridTemplateColumns: "1fr 37.5rem",
                overflow: "hidden",
                position: "relative",
                margin: "0rem",
            }}
        >
            <div
                style={{
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                    backgroundImage: `url('https://ombud.lastbilsstation.se/img/p1066808-2000x1333.jpg')`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }}
            >
                <div
                    style={{
                        position: "absolute",
                        top: "-0.125rem",
                        left: "-0.125rem",
                        width: "17.75rem",
                        height: "17.75rem",
                        borderRadius: "0 0 300px 0",
                        backgroundColor: "rgba(255, 255, 255, 0.8)",
                        border: "0.0625rem solid #55b4d4",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        overflow: 'hidden',
                    }}
                >
                    <Text variant="xxLarge" style={{ fontWeight: 200, padding: 25, marginTop: -35, marginLeft: -40 }}>
                        {"Trafikledning"}
                    </Text>
                </div>
            </div>

            <div
                style={{
                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                    padding: "4.75rem",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    boxSizing: 'border-box',
                }}
            >
                <Stack tokens={{ childrenGap: 15 }}>
                    <Stack.Item>
                        <Text style={{ fontWeight: 200, fontSize: "2.5rem" }}>
                            Välkommen!
                        </Text>
                    </Stack.Item>
                    <Stack.Item>
                        <TextField
                            label="Username"
                            value={username}
                            onChange={(e, newValue) => setUsername(newValue || "")}
                            styles={{                                
                                fieldGroup: {
                                    fontSize: "2.25rem",
                                    borderRadius: "0.3125rem",
                                    height: "2.8125rem",
                                    borderColor: '#55b4d4',
                                    borderWidth: "0.0625rem",
                                    borderStyle: 'solid',
                                },
                            }}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField
                            label="Password"
                            type="password"
                            value={password}
                            onChange={(e, newValue) => setPassword(newValue || "")}
                            styles={{
                                fieldGroup: {
                                    fontSize: "2.25rem",
                                    borderRadius: "0.3125rem",
                                    height: "2.8125rem",
                                    borderColor: '#55b4d4',
                                    borderWidth: "0.0625rem",
                                    borderStyle: 'solid',
                                },
                            }}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <DefaultButton
                            text="Login"
                            onClick={handleLogin}
                            styles={{
                                root: {
                                    borderRadius: "0.3125rem",
                                    width: "100%",
                                    height: "3.125rem",
                                },
                            }}
                        />
                    </Stack.Item>
                </Stack>
            </div>
        </div>
    );
};
