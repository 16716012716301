
import { ArrowDown20Filled, ArrowUp20Filled, BinRecycleFull20Regular, CurrencyDollarEuro20Regular, Document20Regular, Save20Regular, TrayItemRemove20Regular, VehicleCarProfileRtl20Filled } from "@fluentui/react-icons";

interface IMissionInfoProps {
    orderData: any;
}


const MissionInfo = (props: IMissionInfoProps) => {



    return (
        <div className='column'>
            <div className="orderSection" style={{ flexDirection: "column" }}>
                <h3>Uppdrag (ALT + U)</h3>
                <div>
                    <div className="row">
                        <button className="iconButton">
                            <div className="align">
                                <Document20Regular />
                                Ett uppdrag
                            </div>
                        </button>
                        <button className="iconButton">
                            <div className="align">
                                <TrayItemRemove20Regular />
                                Automatfördelning
                            </div>
                        </button>
                        <button className="iconButton">
                            <div className="align">
                                <Save20Regular />
                                Spara
                            </div>
                        </button>
                        <button className="iconButton">
                            <div className="align">
                                <BinRecycleFull20Regular />
                                Ta Bort
                            </div>
                        </button>
                        <button className="iconButton">
                            <div className="align">
                                <CurrencyDollarEuro20Regular />
                                Beräkna resultat
                            </div>
                        </button>
                        <select value={1} className="elongatedSelect">
                            <option value={1}></option>
                        </select>
                        <div className="column">
                            <div className="row">
                                <div>Intäkt:</div>
                                <div>0 kr</div>
                            </div>
                            <div className="row">
                                <div>Kostnad:</div>
                                <div>0 kr</div>
                            </div>
                            <div className="row">
                                <div>Resultat:</div>
                                <div>0 kr</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", flex: 1, flexDirection: "column", flexGrow: 1 }}>
                    <div>
                        text
                    </div>
                </div>
            </div>
            <div className="orderSection" style={{ flexDirection: "column" }}>
                <h3>Adresser (ALT + Z)</h3>
                <div>
                    <div className="row">
                        <button className="iconButton">
                            <div className="align">
                                <Document20Regular />
                                Ny sist
                            </div>
                        </button>
                        <button className="iconButton">
                            <div className="align">
                                <Document20Regular />
                                Ny före
                            </div>
                        </button>
                        <button className="iconButton">
                            <div className="align">
                                <Document20Regular />
                                Ny efter
                            </div>
                        </button>
                        <button className="iconButton">
                            <div className="align">
                                <BinRecycleFull20Regular />
                                Ta Bort
                            </div>
                        </button>
                        <button className="iconButton">
                            <div className="align">
                            <ArrowUp20Filled />
                                Flytta upp
                            </div>
                        </button>
                        <button className="iconButton">
                            <div className="align">
                                <ArrowDown20Filled />
                                Flytta ner
                            </div>
                        </button>
                        <button className="iconButton">
                            <div className="align">
                                <VehicleCarProfileRtl20Filled />
                                Sätt resurs på alla uppdrag
                            </div>
                        </button>
                    </div>
                </div>
                <div style={{ display: "flex", flex: 1, flexDirection: "column", flexGrow: 1 }}>
                    <div>
                        text
                    </div>
                </div>
            </div>
        </div>
    )
}


export default MissionInfo;