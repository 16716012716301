import { calculateCostSummary } from "../../../helpers/CostSummary";
import "../../../styles/styles.css";
import CostDisplay from "../../costDisplay";
import DeliveryTable from "./deliveriesTable";


interface IDeliveriesProps {
    created: any[] | undefined;
    distributed: any[] | undefined;
    loaded: any[] | undefined;
    selectedItem: string | undefined;
    filters: string[];
    onSingleTap: (item: any) => void;
    onDoubleTap: (item: any) => void;
}


const Deliveries = (props: IDeliveriesProps) => {

    const createdStats = calculateCostSummary(props.created || []);
    const distributedStats = calculateCostSummary(props.distributed || []);
    const loadedStats = calculateCostSummary(props.loaded || []);

    return (
        <div style={{ border: "solid 0.0625rem black", padding: 5 }}>
            <div className="row" style={{ alignItems: 'flex-start' }}>
                <div className="column">
                    <div className="expand doubleExpanded">
                        <div className="spacedRow" style={{ padding: "0px" }}>
                            <div className="sectionTitle">Skapade utkörningar</div>
                            <CostDisplay summary={createdStats} />
                        </div>
                        <DeliveryTable items={props.created} selectedItem={props.selectedItem} onSingleTap={props.onSingleTap} onDoubleTap={props.onDoubleTap} filters={props.filters}></DeliveryTable>
                    </div>
                </div>
                <div className="column">
                    <div className="expand">
                        <div className="spacedRow" style={{ padding: "0px" }}>
                            <div className="sectionTitle">Fördelade</div>
                            <CostDisplay summary={distributedStats} />
                        </div>
                        <DeliveryTable items={props.distributed}  selectedItem={props.selectedItem} onSingleTap={props.onSingleTap} onDoubleTap={props.onDoubleTap} filters={props.filters} ></DeliveryTable>
                    </div>
                    <div className="expand">
                        <div className="spacedRow" style={{ padding: "0px" }}>
                            <div className="sectionTitle">Lastade</div>
                            <CostDisplay summary={loadedStats} />
                        </div>
                        <DeliveryTable items={props.loaded}  selectedItem={props.selectedItem} onSingleTap={props.onSingleTap} onDoubleTap={props.onDoubleTap} filters={props.filters} ></DeliveryTable>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Deliveries;