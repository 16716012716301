


interface IOrderData {
  orderData: any;
}

const ServiceList = (props: IOrderData) => {
  return (
    <div className='row'>
      <div className="orderSection">
        <h3>Tjänster (ALT + T)</h3>
        <div>
          <label>Prislista:</label>
          <label>Orderdatum:</label>
          <label>Tjänst:</label>
          <label>Utkörning:</label>
          <label>Ingen autofördelning</label>
          <label>Ingen hämtning</label>
          <label>Ingen utkörning</label>
        </div>
        <div>
          <div>
            <select name="customerId" value={1}>
              <option value={1}>Bruttoprislista</option>
            </select>
          </div>
          <div>
            <input
              type='datetime-local'
              className='form-control'
              id='dateviewStart'
              name='dateviewStart'
              defaultValue={props.orderData.created}
              readOnly
            />
          </div>
          <div>
            <select name="customerId" value={1}>
              <option value={1}>{props.orderData.custName}</option>
            </select>
          </div>
          <div className="row">
            <input type="text" value="100%" readOnly/>
            <input type="text" value={props.orderData.vehicle} readOnly/>
            <input type="checkbox" value="100%" readOnly/>
            <div>Fördela vid spara</div>
          </div>
          <div className="row">
            <input type="checkbox" value="100%" readOnly/>
          </div>
          <div className="row">
            <input type="checkbox" value="100%" readOnly/>
            <div>Direkthämtning</div>
            <input type="checkbox" value="100%" readOnly/>
          </div>
          <div className="row">
            <input type="checkbox" value="100%" readOnly/>
            <div>Direktutkörning</div>
            <input type="checkbox" value="100%" readOnly/>
          </div>
        </div>
      </div>
      <div className="orderSection">
        <h3>Artiklar (ALT + X)</h3>
        <div>
            <label> </label>
          </div>
          <div>
            <div>
              <select name="customerId" value={1}>
                <option value={1}>Bruttoprislista</option>
              </select>
            </div>
          </div>
        {/* <div className="tableContainer">
          <table className="scrollTable">
              <thead>
                  <tr>
                      <th>Godstyp</th>
                      <th>Kollislag</th>
                      <th>Vikt (kg)</th>
                      <th>Volym (m3)</th>
                      <th>Flakmeter</th>
                      <th>Längd (m)</th>
                      <th>Bredd (m)</th>
                      <th>Höjd (m)</th>
                      <th>Kollinummer</th>
                      <th>Antal ({props.orderData.kolliantal})</th>
                      <th>Senaste status</th>
                      <th>Inaktiverad</th>
                      <th>Pallplats</th>
                      <th>Överlastbar</th>
                  </tr>
              </thead>
              <tbody>
                  {props.orderData.kollids.map((kolli: any) => {

                      const event = props.events?.find((item) => item.kolliid == kolli.kolliid);

                      return (
                          <tr key={kolli}>
                              <td>{""}</td>
                              <td>{""}</td>
                              <td>{parseFloat(kolli.kollivikt || "0.0")}</td>
                              <td>{round(parseFloat(kolli.kollilength || "0.0") * parseFloat(kolli.kollibredd || "0.0") * parseFloat(kolli.kolliheight || "0.0"), 4)}</td>
                              <td>{parseFloat(props.orderData.orderflakmeter || "0.0")}</td>
                              <td>{parseFloat(kolli.kollilength || "0.0")}</td>
                              <td>{parseFloat(kolli.kollibredd  || "0.0")}</td>
                              <td>{parseFloat(kolli.kolliheight || "0.0")}</td>
                              <td>{kolli.kolliid}</td>
                              <td>{props.orderData.kolliantal}</td>
                              <td>{event && `${funcationTypeMap[event?.function] || ""} ${(departmentMap[event?.department] || "-") + " terminal"} (${event?.created?.replace(/(t)/i, " ")})`}</td>
                              <td>{}</td>
                              <td>{parseFloat(props.orderData.orderpallplatser || "0.0")}</td>
                              <td></td>
                          </tr>
                      );
                  }).concat(
                      <tr>
                          {Array.from({ length: 14 }).map((_, index) => (
                              <td key={index}> </td>
                          ))}
                      </tr>
                  )}
              </tbody>
          </table>
        </div> */}
      </div>
      <div className="orderSection">
          <h3>Pris (ALT + P)</h3>
          <div>
            <label></label>
            <label>Pris:</label>
            <label>Rabatt:</label>
            <label>Moms:</label>
            <label><input type="checkbox" value="100%" readOnly/></label>
            <label><input type="checkbox" value="100%" readOnly/></label>
            <label><input type="checkbox" value="100%" readOnly/></label>
            <label>Prisberäkning:</label>
          </div>
          <div style={{ marginLeft: 15 }}>
            <div>
              <div>Pris</div>
            </div>
            <div>
              <div>0kr</div>
            </div>
            <div>
              <div>0kr</div>
            </div>
            <div>
              <div>0kr</div>
            </div>
            <div>Inget DMT</div>
            <div>Inget valutatillägg</div>
            <div>Använd sparat pris</div>
            <div>                 
              <select name="customerId" value={1}>
                <option value={1}>Standard</option>
              </select>
            </div>
          </div>
          <div>
            <div>
              <div>Inkl. DMT</div>
            </div>
            <div>
              <div>0kr</div>
            </div>
            <div>
              <div>0kr</div>
            </div>
            <div>
              <div>0kr</div>
            </div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
      </div>
    </div>
  );
};

export default ServiceList;
