

import { Department, Terminal, Vehicle } from "../../models/Department";
import { AppCode } from "../../models/deviationCode";
import { User } from "../../models/UserResult";
import { ActionType } from "../action-types";
import { Action } from "../actions";

const initialState: passiveDataState = {
    departments: [],
    vehicles: [],
    externalTerminals: [],
    users: [],
    appcodes: []
};

export interface passiveDataState {
    departments: Department[];
    vehicles: Vehicle[];
    externalTerminals: Terminal[];
    users: User[];
    appcodes: AppCode[];
}

const activeDataReducer = (state: passiveDataState = initialState, action: Action): passiveDataState => {
  switch (action.type) {
    case ActionType.PASSIVE_ADD:
      return { ...state, ...action.payload };
    case ActionType.PASSIVE_CLEAR:
      return initialState;
    default:
      return state;
  }
};

export default activeDataReducer;
