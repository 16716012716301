


export interface ViewSettings {
    hideinactiveUsers: boolean;
    mulitSearch: boolean;
    showColumnFilter: boolean;
    columnFilters: string[];
    markChangedMissions: boolean;
    sortLiveMissions: boolean;
    showTrailers: boolean;
    separateInvalidPositions: boolean;

    showDsipatchedMissionsForSelected: boolean;
    showPlannedMissionsForSelected: boolean;
    showSuggestedMissionsForSelected: boolean;
    showOnlyDailyMissions: boolean;
    showSelectedMissions: boolean;
    showAllMissionsWithoutUser: boolean;
    showCollectiveLoading: boolean;

    showDrivingOrder: boolean;
    showAdressInfo: boolean;
    showVehicleLines: boolean;
    showDelays: boolean;
    showETA: boolean;
    showMissionTypeIndicator: boolean;

    showOnlyEmptyUsers: boolean;

    displayMissionInfo: boolean;
    displayUserInfo: boolean;
    displayVehicles: boolean;
    displayDrivers: boolean;
    displayTrailers: boolean;
    displayDelegates: boolean;
    displayTerminalUsers: boolean;
    displayOpterLink: boolean;
    displayScheduledEvents: boolean;
    displayRoutes: boolean;
    displayUserSuggestions: boolean;
    displayMissionSuggestions: boolean;
    displayFilters: boolean;
    displayChanges: boolean;
    displayFerries: boolean;
    displayMessages: boolean;
    displayMap: boolean;
    displayOverviewMap: boolean;
    displayrouteOptimize: boolean;
    displayCollectiveLoading: boolean;
}


export const defaultViewSettings: ViewSettings = {
    hideinactiveUsers: false,
    mulitSearch: false,
    showColumnFilter: true,
    columnFilters: ["message", "extra", "sorting", "addon"],
    markChangedMissions: false,
    sortLiveMissions: true,
    showTrailers: false,
    separateInvalidPositions: false,
    showDsipatchedMissionsForSelected: false,
    showPlannedMissionsForSelected: false,
    showSuggestedMissionsForSelected: false,
    showOnlyDailyMissions: false,
    showSelectedMissions: false,
    showAllMissionsWithoutUser: false,
    showCollectiveLoading: false,
    showDrivingOrder: true,
    showAdressInfo: false,
    showVehicleLines: true,
    showDelays: false,
    showETA: false,
    showMissionTypeIndicator: true,
    showOnlyEmptyUsers: true,
    displayMissionInfo: true,
    displayUserInfo: true,
    displayVehicles: true,
    displayDrivers: true,
    displayTrailers: true,
    displayDelegates: true,
    displayTerminalUsers: true,
    displayOpterLink: true,
    displayScheduledEvents: true,
    displayRoutes: true,
    displayUserSuggestions: false,
    displayMissionSuggestions: false,
    displayFilters: true,
    displayChanges: true,
    displayFerries: false,
    displayMessages: true,
    displayMap: true,
    displayOverviewMap: false,
    displayrouteOptimize: false,
    displayCollectiveLoading: false
}