

import { CaretDown20Regular, CaretUp20Regular, Dismiss20Filled } from '@fluentui/react-icons';
import { useState } from 'react';


interface WindowProps {
    title: string;
    disableClose?: boolean;
    children?: JSX.Element | JSX.Element[] | undefined;
    onClose?: () => void;
}


const WindowCard = (props: WindowProps) => {
    
    const [visible, setVisible] = useState<boolean>(true);
    // const [removed, setRemoved] = useState<boolean>(false);

    // if (removed) {
    //     return
    // }

    return (
        <div className='window'>
            <div>
                <div className='windowHeader' style={!visible || props.children === undefined ? { borderBottom: "unset" }: undefined}>
                    <div className='windowTitle'>{props.title}</div>
                    <div style={{ flex: 1 }}></div>
                    {visible ? <CaretDown20Regular onClick={() => setVisible(false)}/>: <CaretUp20Regular onClick={() => setVisible(true)}/>}
                    <div style={{ marginLeft: 3 }}></div>
                    {!props.disableClose && <Dismiss20Filled />}
                </div>
                {visible && props.children !== undefined && <div className='windowContent'>{props.children}</div>}
            </div>
        </div>
    );
}

export default WindowCard;