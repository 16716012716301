import { BrandVariants, createDarkTheme, createLightTheme, FluentProvider, Theme } from '@fluentui/react-components';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './state/store';

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const bolidenLight: BrandVariants = {
  10: "#050301",
  20: "#1E1709",
  30: "#32260F",
  40: "#413112",
  50: "#503C13",
  60: "#604814",
  70: "#715415",
  80: "#826015",
  90: "#936D15",
  100: "#A57A14",
  110: "#B78712",
  120: "#C9951C",
  130: "#D4A547",
  140: "#DEB56A",
  150: "#E8C68C",
  160: "#F0D7AE",
};

const lightTheme: Theme = {
  ...createLightTheme(bolidenLight),
};

const darkTheme: Theme = {
  ...createDarkTheme(bolidenLight),
};

lightTheme.colorBrandBackground = bolidenLight[110];
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <FluentProvider style={{ height: "100%" }} theme={lightTheme}>
        <App />
      </FluentProvider>
    </Provider>
  </React.StrictMode>
);


reportWebVitals();
