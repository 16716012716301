import { MessageResponse, MessageUpload } from "../models/messages";
import { opterApi } from "./api";

export const dispatchOrder = async (orderNr: string, vehicle: string) => {
    const res = await opterApi.post<any>("dispatchOrder", { orderNr, vehicle });
    return res.data;
};


export const newMessage = async (payload: MessageUpload) => {
    const res = await opterApi.post<MessageResponse>("newMessage", payload);
    return res.data;
};