import axios, { AxiosRequestConfig } from "axios";
import { bindActionCreators } from "redux";
import StorageKeys from "../helpers/StorageKeys";
import { actionCreators } from "../state";
import { RootState, store } from "../state/store";
import { getToken } from "./authService";

export const masterUrl = "https://opterweb.lastbilsstation.se/traffic/";

export const axiosOptions: AxiosRequestConfig = {
  headers: { "Content-Type": "application/json" },
  baseURL: masterUrl,
  transitional: {silentJSONParsing: true, forcedJSONParsing: true, clarifyTimeoutError: true},
  timeout: 40000,
  adapter: ["xhr","http","https"],
};


export let opterApi = axios.create(axiosOptions);

opterApi.interceptors.request.use(
  async (config) => {
    try {
      const state = store.getState() as RootState;
      const token = state.auth?.auth?.accessToken;
      const departmentCode = state.auth?.auth.login.department;

      config.headers.Authorization = `Bearer ${token}`;

      config.headers["departmentCode"] = departmentCode;
    } catch (error) {
      console.log("request interceptor error", error);
    } finally {
      return config;
    }
  },
  (error) => {
    console.log("request config error ", error);
    return Promise.reject(error);
  }
);
  


opterApi.interceptors.response.use(
  (response) => response,
  async (error) => {

    console.log("error", error);
    const originalRequest = error.config;

    console.log(`interceptor triggered code ${error.response.status} (${error.config.url})`);

    if (error.response.status === 401 && !originalRequest._retry) {
      console.log("getting new access token");
      try {
        const addAuth = bindActionCreators(actionCreators, store.dispatch).addAuth;
        const result = await getToken();

        const newAuth = (store.getState() as RootState)?.auth;

        if (!newAuth || !result?.accessToken) return;

        newAuth.auth.accessToken = result.accessToken;

        localStorage.setItem(StorageKeys.user, JSON.stringify(newAuth.auth));

        addAuth({ auth: newAuth.auth });

        originalRequest.headers.Authorization = `Bearer ${result.accessToken}`;
        originalRequest._retry = true;

        console.log("retrying request");
        return opterApi.request(originalRequest);
        
      } catch (e) {
        console.log("get token error", e);
      }
    }
    else {
      return Promise.reject(error);
    }
  }
);