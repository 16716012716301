

import { Radio, RadioGroup } from '@fluentui/react-components';
import { useState } from 'react';
import { formatASCII } from '../Services/format';
import "../styles/styles.css";


interface IMissionInfoProps {
    mission: Record<string, any>;
}

const MissionInfoWindow = (props: IMissionInfoProps): JSX.Element => {

    const messageOptions = ["Sammanställning", "Internt", "Externt meddelande", "leveransinstruktioner", "Extra"];

    const [selectedRadio, setSelectedRadio] = useState<string>("0");
    const [selectedRadioMessage, setSelectedRadioMessage] = useState<string>("0");

    const compDate = props.mission.created.split(" ")[0]?.slice(2).replaceAll("-", "");

    const loadStartDate = props.mission.fromTimePickup.split("T")[1]?.replaceAll("-", ":")?.slice(0, 5);
    const loadEndDate = props.mission.toTimePickup.split("T")[1]?.replaceAll("-", ":")?.slice(0, 5);

    const deliverStartDate = props.mission.recFromTimeDeliver.split("T")[1]?.replaceAll("-", ":")?.slice(0, 5);
    const deliverEndDate = props.mission.recLastLevTime.split("T")[1]?.replaceAll("-", ":")?.slice(0, 5);

    return (
        <div className="spacedRow" style={{ padding: "0px", gap: "0.75rem" }}>
            <div className="column" style={{ gap: "0px", minWidth: "unset" }}>
                <div className='missionTitle'>Allmänt</div>
                <div className='missionItem'>Skellefteå lastbilsstation</div>
                <div className='missionItem'>{props.mission.orderNr}</div>
                <div className='missionItem'>{"?"}</div>
                <div className='missionItem'>System.</div>
                <div className='missionItem'>{props.mission.created?.replace("T", " ").replace(/-00$/, "")}</div>
            </div>
            <div className="column min11" style={{ gap: "0px", minWidth: "unset" }}>
                <div className='missionTitle'>Tider</div>
                <div className='missionItem'>{`Lastas tidigast:  ${compDate} ${(loadStartDate || "")}`}</div>
                <div className='missionItem'>{`Lastas senast:    ${compDate} ${(loadEndDate || "")}`}</div>
                <div className='missionItem'>{`Lossas tidigast:  ${compDate} ${(deliverStartDate || "")}`}</div>
                <div className='missionItem'>{`Lossas senast:    ${compDate} ${(deliverEndDate || "")}`}</div>
            </div>
            <div className="column " style={{ gap: "0px", minWidth: "unset" }}>
                <div className="spacedRow min24" style={{ padding: "0px" }}>
                    <div className='missionTitle'>Adress</div>
                    <RadioGroup style={{ flexDirection: "row" }}>
                        <Radio value="0" label="Uppdrag" checked={selectedRadio == "0"} onChange={(value) => setSelectedRadio(value.target.value)} />
                        <Radio value="1" label="Order" checked={selectedRadio == "1"} onChange={(value) => setSelectedRadio(value.target.value)} />
                    </RadioGroup>
                </div>
                <RadioInfoSection index={Number(selectedRadio)} info={props.mission} />
            </div>
            <div className="column" style={{ gap: "0px", minWidth: "unset" }}>
                <div className='missionTitle'>Dimensioner</div>
                <div className='missionItem'>{"Vikt:   " + parseFloat(props.mission.ordervikt || "0.0") + " kg"}</div>
                <div className='missionItem'>{"Kollin: " + (props.mission.kolliantal || "") + " st"}</div>
            </div>
            <div className="column" style={{ gap: "0px", minWidth: "unset" }}>
                <div className='missionTitle'>Meddelanden</div>
                <RadioGroup style={{ gap: "0px" }}>
                    {messageOptions.map((message, index) => (
                        <Radio className='radioItem' key={index} value={index.toString()} label={message} checked={selectedRadioMessage == index.toString()} onChange={(value) => setSelectedRadioMessage(value.target.value)} />
                    ))}
                </RadioGroup>
            </div>
            <input style={{ height: "80%", width: "80%" }}></input>
        </div>
    );
}

export default MissionInfoWindow;


interface IRadioInfoSectionProps {
    index: number;
    info: any | undefined;
}

const RadioInfoSection = (props: IRadioInfoSectionProps): JSX.Element => {

    switch (props.index) {
        case 0: 
        return (
            <div className="spacedRow" style={{ padding: "0px" }}>
                <div>
                    <div className='missionItem'>{(props.info.custNr || "-") + " " + (props.info.avsCompany?.replace("/", "+").replace(/([a-zåäö0-9]+)\s?(ab)(.*)/i, "$1 $2 $3") || "-")}</div>
                    <div className='missionItem'>{formatASCII(props.info.avsAdrHouseNr) || "-"}</div>
                    <div className='missionItem'>{(props.info.avsPostnr || "-") + " " + (formatASCII(props.info.avsPostOrt) || "-")}</div>
                    <div className='missionItem'>{(props.info.avsPhone || "-")}</div>
                </div>
                <div>
                    <div>{props.info.recCompany?.replace("/", "+").replace(/([a-zåäö0-9]+)\s?(ab)(.*)/i, "$1 $2 $3") || "-"}</div>
                    <div className='missionItem'>{formatASCII(props.info.recadressHouseNr) || "-"}</div>
                    <div className='missionItem'>{(props.info.recPostnr || "-") + " " + (formatASCII(props.info.recPostOrt) || "-")}</div>
                    <div className='missionItem'>{(props.info.recPhone || "-")}</div>
                </div>
            </div>
        )
        case 1:
        return (
            <div className="column">
                <div className='missionItem'>{props.info.task}</div>
                <div className='missionItem'>{props.info.taskType}</div>
                <div className='missionItem'>{props.info.taskDescription}</div>
            </div>
        )
    }

    return <div></div>;
}