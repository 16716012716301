import { pods } from "../helpers/TranslationMaps";
import { CurrentOrderData } from "../models/CurrentOrderData";
import "../styles/styles.css";



interface IstatusMenueProps {
    activeOrders: CurrentOrderData | undefined;
}


const StatusMenue = (props: IstatusMenueProps) => {

    let messageCount = 0;
    let deniedCount = 0;
    let pendingCount = 0;
    let linkMessageCount = 0;

    const orderCount = (props.activeOrders?.unDistributed?.length || 0)

    for (let i = 0; i < orderCount; i++) {
        if (props.activeOrders!.unDistributed![i].pod == pods.denied) {
            deniedCount++;
        }
    }


    return (
        <div className='messageSection'>
            <div className='messageStat'>
                <div className="statusText">meddelanden</div>
                <div className="statusText">{messageCount}</div>
            </div>
            <div className='messageStat'>
                <div className="statusText">nekade uppdrag</div>
                <div className="statusText">{deniedCount}</div>
            </div>
            <div className='messageStat'>
                <div className="statusText">väntande förändringar</div>
                <div className="statusText">{pendingCount}</div>
            </div>
            <div className='messageStat'>
                <div className="statusText">link meddelanden</div>
                <div className="statusText">{linkMessageCount}</div>
            </div>
        </div>
    );
}


export default StatusMenue;