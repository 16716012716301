


import React from "react";
import { columnLableMap, customerTypeMap, podColorMap, podLableMap, pods, timeColorMap, timeWindow } from "../../../helpers/TranslationMaps";
import { IBaseTableProps } from "../../../models/BaseTable";
import { formatASCII } from "../../../Services/format";
import "../../../styles/styles.css";
import '../../../styles/Tables.css';
import ColumnSorting, { IColumnSearch, IColumnSorting } from "../../columnSorting";
import { DotLoadingIndicator } from "../../loadingIndicator";

export interface IPickupTableProps extends IBaseTableProps {
  onTap: (item: any) => void;
}

const PickupTable = (props: IPickupTableProps) => {

  const [search, setSearch] = React.useState<IColumnSearch | undefined>(undefined);
  const [sorting, setSorting] = React.useState<IColumnSorting | undefined>(undefined);

  const columns: string[] = [
    "pod",
    "created",
    "fromTimePickup",
    "toTimePickup",
    "avsCompany",
    "avsAdrHouseNr",
    "avsPostOrt",
    "avsPostnr",
    "custNr",
    "custName",
    "recCompany",
    "recadressHouseNr",
    "recPostOrt",
    "recPostnr",
  ];

  // const maxes = Array.from({ length: columns.length }, () => 0);

  // props.items?.forEach((item: any) => {
  //   columns.forEach((column, index) => {

  //     let value = item[column];

  //     if (column == "pod") {
  //       value = podLableMap[value];
  //     } else if (column == "created") {
  //       value = value?.split(" ")[0]?.replace(/-00$/, "");
  //     } else if (column == "fromTimePickup" || column == "toTimePickup") {
  //       value = value?.split("T")[1]?.replace(/-00$/, "");
  //     }

  //     maxes[index] = Math.max(maxes[index], (value || "").toString().length);
  //   })
  // });

  // console.log("maxes", maxes.map((max, index) => columns[index] + ": " + max));

  return (
    <table className="general-table">
      <thead>
        <tr>
          {columns.filter((item) => !props.filters.includes(item)).map((column) => (
            <th onClick={() => setSorting((prev) => ({ column: column, descending: prev?.column == column ? !prev?.descending: false }))}>{columnLableMap[column] + (sorting !== undefined && sorting?.column == column ? (sorting.descending ? " ↓": " ↑"): "")}</th>
          ))}
        </tr>
        <tr>
          {columns.filter((item) => !props.filters.includes(item)).map((column) => (
            <ColumnSorting column={column} onSearchChange={(search) => setSearch(search)} onSortChange={(sorting) => setSorting(sorting)} />
          ))}
        </tr>
      </thead>
      {props.items !== undefined ? <tbody>
        {props.items.filter((item: any) => search === undefined || (item[search.column] || "").toString().toLowerCase().includes(search.search)).sort((a: any, b: any) => sorting === undefined ? 0 : ((a[sorting.column] || "").toString().localeCompare((b[sorting.column] || "").toString()) * (sorting.descending ? -1: 1))).map((item: any, index: number) => {
          const diff = Date.now() - Date.parse(item.created);

          const custNrColor = customerTypeMap[item.custNr];
          const denied = item.pod == pods.denied;

          return (
            <tr key={index} className={item.orderNr == props.selectedItem ? "selectedRow": ""} onClick={() => props.onTap(item)}>

              <td style={{ backgroundColor: podColorMap[item.pod], color: denied ? "#ffffff": undefined }}>{podLableMap[item.pod]}</td>
              <td style={{ backgroundColor: timeColorMap[ diff > timeWindow ? "passed": "" ] }}>{item.created?.split(" ")[0]?.replace(/-00$/, "")}</td>

              <td>{item.fromTimePickup?.split("T")[1]?.replace(/-00$/, "")}</td>
              <td>{item.toTimePickup?.split("T")[1]?.replace(/-00$/, "")}</td>

              <td>{formatASCII(item.avsCompany)}</td>
              <td>{formatASCII(item.avsAdrHouseNr)}</td>
              <td>{formatASCII(item.avsPostOrt)}</td>
              <td>{item.avsPostnr}</td>

              <td style={{ backgroundColor: custNrColor, color: custNrColor && "#ffffff" }}>{item.custNr}</td>
              <td>{item.custName}</td>

              <td>{formatASCII(item.recCompany)}</td>
              <td>{formatASCII(item.recadressHouseNr)}</td>
              <td>{formatASCII(item.recPostOrt)}</td>
              <td>{item.recPostnr}</td>
            </tr>
          )
        })}
      </tbody>: <DotLoadingIndicator />}
    </table>
  );
};

export default React.memo(PickupTable);