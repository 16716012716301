import { ToastOptions } from "./snackbar";


export enum Events {
  SHOW_GLOBAL_TOASTER = "show-global-toaster",
}

export interface GlobalToaster {
  title: string;
  subtitle?: string;
  footer?: JSX.Element;
  options?: ToastOptions;
}


export const eventEmitter = {
  _events: {} as any,
  dispatch(event: Events, data?: any) {
    if (!this._events[event]) return;
    this._events[event].forEach((callback: any) => callback(data));
  },
  subscribe(event: Events, callback: (data?: any) => any) {
    if (!this._events[event]) this._events[event] = [];
    this._events[event].push(callback);
  },
  unsubscribe(event: Events) {
    if (!this._events[event]) return;
    delete this._events[event];
  },
};
