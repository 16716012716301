import { calculateCostSummary } from "../../../helpers/CostSummary";
import "../../../styles/styles.css";
import CostDisplay from "../../costDisplay";
import DeliveryTable from "../Deliveries/deliveriesTable";


interface IDeliveriesProps {
    created: any[] | undefined;
    selectedItem: string | undefined;
    filters: string[];
    onSingleTap: (item: any) => void;
    onDoubleTap: (item: any) => void;
}


const ComingDeliveries = (props: IDeliveriesProps) => {

    const createdStats = calculateCostSummary(props.created || []);

    return (
        <div style={{ border: "solid 0.0625rem black", alignSelf: "start" }}>
            <div className="column">
                <div>
                    <div className="spacedRow" style={{ padding: "0px" }}>
                        <div className="sectionTitle">Skapade utkörningar</div>
                        <CostDisplay summary={createdStats} />
                    </div>
                    <DeliveryTable items={props.created} selectedItem={props.selectedItem} onSingleTap={props.onSingleTap} onDoubleTap={props.onDoubleTap} filters={props.filters} ></DeliveryTable>
                </div>
            </div>
        </div>
    )
}


export default ComingDeliveries;