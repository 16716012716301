// App.tsx
import { useToastController } from "@fluentui/react-toast";
import { useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { bindActionCreators } from "redux";
import Snackbar from './components/Snackbar';
import { eventEmitter, Events, GlobalToaster } from './helpers/eventEmitter';
import StorageKeys from "./helpers/StorageKeys";
import { AuthResult } from "./models/AuthResult";
import { LogInPage } from './pages/LoginPage';
import GeneralPage from './pages/MainPage';
import OrderPage from "./pages/OrderPage";
import ProtectedRoute from "./routeGuard";
import { tokenLifetime } from "./Services/authService";
import { actionCreators } from "./state";
import { passiveDataState } from "./state/reducers/passiveDataReducer";
import { store } from "./state/store";


export const globalToasterId = "global-toaster";

const App = () => {
  const dispatchToast = useToastController(globalToasterId).dispatchToast;

  useEffect(() => {
    eventEmitter.subscribe(
      Events.SHOW_GLOBAL_TOASTER,
      (data: GlobalToaster) => {
        dispatchToast(
          <Snackbar
            title={data.title}
            subtitle={data.subtitle}
            footer={data.footer}
          />,
          { ...data.options }
        );
      }
    );
    return () => {
      eventEmitter.unsubscribe(Events.SHOW_GLOBAL_TOASTER);
    };
  }, []);

  const auth = JSON.parse(localStorage.getItem(StorageKeys.user) || "{}") as AuthResult | undefined;

  const tokenTime = tokenLifetime(auth?.refreshToken);

  console.log("Stored tokenTime", auth?.refreshToken !== undefined ? "defined": "undefined", tokenTime.toFixed(1), "min");

  if (tokenTime > 30) {

    const addAuth = bindActionCreators(actionCreators, store.dispatch).addAuth;
    const addPassive  = bindActionCreators(actionCreators, store.dispatch).addPassiveData;

    addAuth({ auth: auth! });

    const pasisve = JSON.parse(localStorage.getItem(StorageKeys.passiveData) || "{}") as passiveDataState | undefined;

    if (pasisve) {
      console.log("Adding passive data");

      addPassive(pasisve);
    }
  }

  return (
    <BrowserRouter basename={""}>
      <Routes>
        <Route path="/" element={<Navigate to={tokenTime > 30 ? "/Main":  "/Login"} replace />} />
        <Route path="/Login" element={<LogInPage />} />
        <Route
          path="/Main"
          element={
            <ProtectedRoute>
              <GeneralPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Order"
          element={
            <ProtectedRoute>
              <OrderPage />
            </ProtectedRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
