


import React from "react";
import { columnLableMap, customerTypeMap, podLableMap, pods, timeColorMap, timeWindow } from "../../../helpers/TranslationMaps";
import { IBaseTableProps } from "../../../models/BaseTable";
import { formatASCII } from "../../../Services/format";
import "../../../styles/styles.css";
import '../../../styles/Tables.css';
import ColumnSorting, { IColumnSearch, IColumnSorting } from "../../columnSorting";
import { DotLoadingIndicator } from "../../loadingIndicator";

export interface IPickupTableProps extends IBaseTableProps {
  onSingleTap: (item: any) => void;
  onDoubleTap: (item: any) => void;
}

const CreatedPickupTable = (props: IPickupTableProps) => {

  const [search, setSearch] = React.useState<IColumnSearch | undefined>(undefined);
  const [sorting, setSorting] = React.useState<IColumnSorting | undefined>(undefined);

  const columns: string[] = [
    "orderNr",
    "created",
    "custNr",
    "avsCompany",
    "avsAdrHouseNr",
    "avsPostOrt",
    "avsPostnr",
    "fromTimePickup",
    "toTimePickup",
    "recCompany",
    "recadressHouseNr",
    "recPostOrt",
    "recPostnr",
    "fromTimePickup",
    "toTimePickup",
    "vehicle",
    "kolliantal",
    "ordervikt",
    "ordervolume",
    "orderflakmeter",
    "orderpallplatser",
    "cargoType",
    "pod",
    "message",
    "extra",
    "sorting"
  ];

  console.log("filters", props.filters);

  return (
    <table className="general-table">
      <thead>
        <tr>
          {columns.filter((item) => !props.filters.includes(item)).map((column) => (
            <th onClick={() => setSorting((prev) => ({ column: column, descending: prev?.column == column ? !prev?.descending: false }))}>{columnLableMap[column] + (sorting !== undefined && sorting?.column == column ? (sorting.descending ? " ↓": " ↑"): "")}</th>
          ))}
        </tr>
        <tr>
          {columns.filter((item) => !props.filters.includes(item)).map((column) => (
            <ColumnSorting column={column} onSearchChange={(search) => setSearch(search)} onSortChange={(sorting) => setSorting(sorting)} />
          ))}
        </tr>
      </thead>
      {props.items !== undefined ? <tbody>
        {props.items.filter((item: any) => search === undefined || (item[search.column] || "").toString().toLowerCase().includes(search.search)).sort((a: any, b: any) => sorting === undefined ? 0 : ((a[sorting.column] || "").toString().localeCompare((b[sorting.column] || "").toString()) * (sorting.descending ? -1: 1))).map((item: any, index: number) => {
          const diff = Date.now() - Date.parse(item.created);

          const custNrColor = customerTypeMap[item.custNr];
          const denied = item.pod == pods.denied;

          const weight = parseFloat(item.ordervikt);
          const volume = parseFloat(item.ordervolume);
          const flakmeter = parseFloat(item.orderflakmeter);
          const pallplatser = parseFloat(item.orderpallplatser);

          return (
            <tr key={index} className={item.orderNr == props.selectedItem ? "selectedRow": ""} onClick={() => props.onSingleTap(item)} onDoubleClick={() => props.onDoubleTap(item)}>

              <td style={{ color: denied ? "#ffffff": undefined }}>{podLableMap[item.pod]}</td>
              <td style={{ backgroundColor: timeColorMap[ diff > timeWindow ? "passed": "" ] }}>{item.created?.split(" ")[0]?.replace(/-00$/, "")}</td>
              <td style={{ backgroundColor: custNrColor, color: custNrColor && "#ffffff" }}>{item.custNr}</td>

              <td>{formatASCII(item.avsCompany)}</td>
              <td>{formatASCII(item.avsAdrHouseNr)}</td>
              <td>{formatASCII(item.avsPostOrt)}</td>
              <td>{item.avsPostnr}</td>

              <td>{item.fromTimePickup?.split("T")[1]?.replace(/-00$/, "")}</td>
              <td>{item.toTimePickup?.split("T")[1]?.replace(/-00$/, "")}</td>

              <td>{formatASCII(item.recCompany)}</td>
              <td>{formatASCII(item.recPostOrt)}</td>

              <td>{item.fromTimePickup?.split("T")[1]?.replace(/-00$/, "")}</td>
              <td>{item.toTimePickup?.split("T")[1]?.replace(/-00$/, "")}</td>

              <td>{item.vehicle}</td>
              <td>{item.kollin}</td>

              <td>{weight > 0 ? weight.toFixed(1): ""}</td>
              <td>{volume > 0 ? volume.toFixed(1): ""}</td>
              <td>{flakmeter > 0 ? flakmeter.toFixed(1): ""}</td>
              <td>{pallplatser > 0 ? pallplatser.toFixed(1): ""}</td>

              <td>{item.orderNr}</td>
              <td>{item.pnr}</td>
              <td>{item.cargoType}</td>

              {!props.filters.includes("message") && <td>{item.message}</td>}
              {!props.filters.includes("extra") && <td>{item.extra}</td>}
              {!props.filters.includes("sorting") && <td>{item.sorting}</td>}
            </tr>
          )
        })}
      </tbody>: <DotLoadingIndicator />}
    </table>
  );
};

export default React.memo(CreatedPickupTable);