import L from "leaflet";
import moment from "moment";
import React, { useEffect, useMemo } from "react";
import { MapContainer, Marker, Polygon, Popup, TileLayer, useMap } from "react-leaflet";
import { Department } from "../models/Department";
import { User } from "../models/UserResult";
import { activeDataState } from "../state/reducers/activeDataReducer";
import "../styles/styles.css";
import { DotLoadingIndicator } from "./loadingIndicator";


interface DepartmentsPolygonsProps {
  departments: Department[];
}

interface RecenterMapProps {
  center: L.LatLngExpression;
  zoom: number;
}
  
const DepartmentsPolygons: React.FC<DepartmentsPolygonsProps> = ({ departments }) => {
  return (
    <>
      {departments.map((department, index) => {
        const { minLat, minLong, maxLat, maxLong, transform, departmentName } = department;

        if (minLat != null && minLong != null && maxLat != null && maxLong != null) {
          const rotationDegrees = transform || 0;
          const rotationRadians = (rotationDegrees * Math.PI) / 180;

          const centerLat = (minLat + maxLat) / 2;
          const centerLong = (minLong + maxLong) / 2;

          const corners = [
            [minLat, minLong],
            [minLat, maxLong],
            [maxLat, maxLong],
            [maxLat, minLong],
          ];

          const rotatedCorners: L.LatLngExpression[] = corners.map(([lat, lng]) => {
            const transX = lng - centerLong;
            const transY = lat - centerLat;

            const rotatedX = transX * Math.cos(rotationRadians) - transY * Math.sin(rotationRadians);
            const rotatedY = transX * Math.sin(rotationRadians) + transY * Math.cos(rotationRadians);

            const finalLng = rotatedX + centerLong;
            const finalLat = rotatedY + centerLat;

            return [finalLat, finalLng];
          });

          return (
            <Polygon key={index} positions={rotatedCorners} color="#ff7800" weight={1}>
              <Popup>{departmentName}</Popup>
            </Polygon>
          );
        }

        return null;
      })}
    </>
  );
};

const RecenterMap: React.FC<RecenterMapProps> = ({ center, zoom }) => {
  const map = useMap();

  useEffect(() => {
    if (center) {
      map.flyTo(center, zoom, {
        duration: 0.8,
      });
    }
  }, [center, zoom]);

  return null;
};


function getMapIcon(rotation: number): L.DivIcon {
  return L.divIcon({
    html: `<img src="https://uxwing.com/wp-content/themes/uxwing/download/arrow-direction/map-direction-navigation-top-blue-icon.png" style="transform-origin: center; transform: rotate(${rotation}deg); width: 1.25rem; height: 1.25rem;">`,
    iconSize: [18, 18],
    className: '',
  });
}
  
function Markers(props: MapProps) {
  const filteredUsers = props.data.usePositions.filter(
    (user) => (props.department?.departmentCode === undefined || user.department == props.department?.departmentCode || props.users.find((u) => u.id == user.uid)?.department == props.department?.departmentCode) && moment().diff(moment(user.timestamp), 'minutes') < 20
  );

  return (
    <>
      {filteredUsers.map((user) => (
        <Marker key={user.uid} position={[user.latitude, user.longitude]} icon={getMapIcon(user.heading)}>
          <Popup autoPan className="mapPopup">
            {`${user.username}, ${user.vehicle}, ${!user.terminal ? ((user.speed * 3.6).toFixed(0) + " km/h, "): ""}${user.city}\n${user.timestamp.split(" ")[1]}`}
          </Popup>
        </Marker>
      ))}
    </>
  );
};
  
interface MapProps {
  data: activeDataState;
  users: User[];
  department: Department | undefined;
  departments: Department[];
}
  
const MapComponent = (props: MapProps) => {
  const position: L.LatLngExpression | undefined = useMemo(() => props.department && [(props.department.minLat + props.department.maxLat) / 2, (props.department.minLong + props.department.maxLong) / 2], [props.department]);
  
  if (!position) {
    return (
      <div className="mapContainer">
        <DotLoadingIndicator />
      </div>
    );
  }
  
  return (
    <div className="mapContainer">
      <MapContainer
        center={position}
        zoom={7.75}
        style={{ height: '100%', width: '100%' }}
      >
        <TileLayer
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          maxZoom={19}
        />
        <Markers data={props.data} users={props.users} department={props.department} departments={props.departments} />
        <RecenterMap center={position} zoom={7.75} />
        <DepartmentsPolygons departments={props.departments} />
      </MapContainer>
    </div>
  );
};
  
export default React.memo(MapComponent);