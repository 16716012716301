import { Department } from "../../models/Department";
import { ActionType } from "../action-types";
import { Action } from "../actions";

const initialState: ClientOptionState = {
  selectedDepartment: undefined,
};

export interface ClientOptionState {
  selectedDepartment?: Department;
}

const clientOptionReducer = (state: ClientOptionState = initialState, action: Action): ClientOptionState => {
  switch (action.type) {
    case ActionType.CLIENT_OPTIONS_ADD:
      return { ...state, ...action.payload };
    case ActionType.CLIENT_OPTIONS_CLEAR:
      return initialState;
    default:
      return state;
  }
};

export default clientOptionReducer;
