import { FluentIcon } from "@fluentui/react-icons";





const IconToggleButton = ({ Icon, text, column, onClick, isActive }: { Icon: FluentIcon, text: string, column?: boolean, onClick: () => void, isActive: boolean }) => {

    return (
        <div className={isActive ? 'actionButton': 'inactiveActionButton'} style={{ display: "flex", flexDirection: column ? "column": "row", alignItems: "center" }} onClick={onClick}>
            <Icon height="1.25rem" width="1.25rem" />
            <div style={{ marginLeft: "0.21875rem", fontSize: "0.79rem" }}>{text}</div>
        </div>
    );
}


export default IconToggleButton;