


import React from 'react';
import { columnLableMap, customerTypeMap, podColorMap, podLableMap, pods, timeColorMap, timeWindow } from '../../../helpers/TranslationMaps';
import { IBaseTableProps } from '../../../models/BaseTable';
import { formatASCII } from '../../../Services/format';
import '../../../styles/Tables.css';
import ColumnSorting, { IColumnSearch, IColumnSorting } from '../../columnSorting';
import { DotLoadingIndicator } from '../../loadingIndicator';

export interface IPickupTableProps extends IBaseTableProps {
  onSingleTap: (item: any) => void;
  onDoubleTap: (item: any) => void;
}

const DeliveryTable = (props: IPickupTableProps) => {

  const [search, setSearch] = React.useState<IColumnSearch | undefined>(undefined);
  const [sorting, setSorting] = React.useState<IColumnSorting | undefined>(undefined);

  const columns: string[] = [
    "pod",
    "created",
    "recCompany",
    "recadressHouseNr",
    "recPostOrt",
    "recPostnr",
    "extraid5",
    "custNr",
    "custName",
    "avsCompany",
    "avsPostOrt",
    "arrivalDate",
  ];


  return (
    <table className="general-table">
      <thead>
        <tr>
          {columns.filter((item) => !props.filters.includes(item)).map((column) => (
            <th onClick={() => setSorting((prev) => ({ column: column, descending: prev?.column == column ? !prev?.descending: false }))}>{columnLableMap[column] + (sorting !== undefined && sorting?.column == column ? (sorting.descending ? " ↓": " ↑"): "")}</th>
          ))}
        </tr>
        <tr>
          {columns.filter((item) => !props.filters.includes(item)).map((column) => (
            <ColumnSorting column={column} onSearchChange={(search) => setSearch(search)} onSortChange={(sorting) => setSorting(sorting)} />
          ))}
        </tr>
      </thead>
      {props.items !== undefined ? <tbody>
        {props.items.filter((item: any) => search === undefined || (item[search.column] || "").toString().toLowerCase().includes(search.search)).sort((a: any, b: any) => sorting === undefined ? 0 : ((a[sorting.column] || "").toString().localeCompare((b[sorting.column] || "").toString()) * (sorting.descending ? -1: 1))).map((item: any, index: number) => {
          const diff = Date.now() - Date.parse(item.created);

          const custNrColor = customerTypeMap[item.custNr];
          const denied = item.pod == pods.denied;

          return (
            <tr key={index} className={item.orderNr == props.selectedItem ? "selectedRow": ""} onClick={() => props.onSingleTap(item)} onDoubleClick={() => props.onDoubleTap(item)}>
              <td style={{ backgroundColor: podColorMap[item.pod], color: denied ? "#ffffff": undefined }}>{podLableMap[item.pod]}</td>
              <td style={{ backgroundColor: timeColorMap[ diff > timeWindow ? "passed": "" ] }}>{item.created?.split(" ")[0]?.replace(/-00$/, "")}</td>

              <td>{formatASCII(item.recCompany)}</td>
              <td>{formatASCII(item.recadressHouseNr)}</td>
              <td>{formatASCII(item.recPostOrt)}</td>
              <td>{item.recPostnr}</td>

              <td>{item.extraid5}</td>
              <td style={{ backgroundColor: custNrColor, color: custNrColor && "#ffffff" }}>{item.custNr}</td>
              <td>{item.custName}</td>

              <td>{formatASCII(item.avsCompany)}</td>
              <td>{formatASCII(item.avsPostOrt)}</td>

              <td>{(item.arrivalDate as string | undefined)?.slice(0, 16)}</td>
            </tr>
          )
        })}
      </tbody>: <DotLoadingIndicator />}
    </table>
  );
};

export default React.memo(DeliveryTable);