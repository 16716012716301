import { ArrowCounterclockwise20Regular, CalendarDate20Regular, Chat20Regular, Clock20Regular, DocumentSearch20Regular, DocumentText20Regular, Folder20Regular, Person20Filled, Search20Regular, ZoomIn20Regular } from "@fluentui/react-icons";
import { Department } from "../../models/Department";
import "../../styles/styles.css";
import IconToggleButton from "../IconToggleButton";

import { ViewSettings } from "../../models/ViewSettings";


interface IGeneralTableProps {
    settings: ViewSettings;
    updateSettings: React.Dispatch<React.SetStateAction<ViewSettings>>;
    departments: Department[];
    selectedItem: any | undefined;
    selectedDepartment: Department | undefined;
    onDepartmentChange: (department: Department | undefined) => void;
}



const General = (props: IGeneralTableProps) => {

    const now = new Date();

    const openNewTab = (data: any | undefined) => {
        if (!data) return;

        const url = `/Order?data=${encodeURIComponent(data.orderNr)}`;
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    return (
        <div className='topmenue'>
            <div className='smallSection'>
                <div>
                    <div className='spacedRow m-1'>
                        <IconToggleButton
                            Icon={Search20Regular}
                            text="Nollställ sök"
                            onClick={() => {}}
                            isActive={false}
                        />
                        <IconToggleButton
                            Icon={Person20Filled}
                            text="Skapade av mig"
                            onClick={() => {}}
                            isActive={false}
                        />
                    </div>
                    <div style={{ flex: 1, flexDirection: "row" }}>
                        <input
                            className='searchInput'
                            name='OFF_Id'
                            placeholder='sök'
                            type='text'
                            onChange={() => {}}
                        />
                        <button style={{ marginLeft: 10, backgroundColor: "white" }}>
                            sök
                        </button>
                    </div>
                </div>
            </div>
            <div className='largeSection'>
                <div className='spacedRow'>
                    <div>
                        <div className='spacedRow'>
                            <div className='input-group-prepend'>
                                <label
                                    className="settingText"
                                    htmlFor='inputGroupSelect01'
                                >
                                    {"Inställning: "} 
                                </label>
                            </div>
                            <select
                                className='dropdown'
                                id='departmentView'
                                name='departmentView'
                                onChange={() => {}}
                                style={{ marginLeft: 5 }}
                            >
                                <option id="standard" value="standard">standard</option>
                                {/* {props.departments.map((department) => (
                                    <option key={department.id} value={department.id}>
                                        {department.departmentName}
                                    </option>
                                ))} */}
                            </select>
                        </div>
                        <div className='spacedRow'>
                            <div className='input-group-prepend'>
                                <label 
                                    className="settingText"
                                    htmlFor='inputGroupSelect01'
                                >
                                    {"Filter: "}
                                </label>
                            </div>
                            <select
                                className='dropdown'
                                id='filterView'
                                name='filterView'
                                value={props.selectedDepartment?.id}
                                onChange={(item) => {
                                    if (item.target.value === "--") {
                                        props.onDepartmentChange(undefined);
                                        return;
                                    }

                                    const dep = props.departments.find((department) => department.id == item.target.value);

                                    if (dep) {
                                        props.onDepartmentChange(dep);
                                    }
                                }}
                                style={{ marginLeft: 5 }}
                            >
                                <option id="--" value="--">--</option>
                                {props.departments.map((department) => (
                                    <option key={department.id} value={department.id}>
                                        {department.departmentName}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className='column'>
                        <div className='inactiveActionButton' style={{ display: "flex", flexDirection: "row" }}>
                            <CalendarDate20Regular />
                            <div style={{ marginLeft: 3 }}>
                                <input
                                    type='datetime-local'
                                    className='form-control'
                                    id='dateviewStart'
                                    name='dateviewStart'
                                    defaultValue={now.toISOString().slice(0, 16)}
                                />
                            </div>
                        </div>
                        <IconToggleButton
                            Icon={ZoomIn20Regular}
                            text="Förplanering"
                            onClick={() => {}}
                            isActive={false}
                        />
                    </div>
                    <div className='column'>
                        <IconToggleButton
                            Icon={Chat20Regular}
                            text="skicka meddelanden"
                            onClick={() => {}}
                            isActive={true}
                        />
                        <IconToggleButton
                            Icon={Clock20Regular}
                            text="dölj inaktiva resurser"
                            onClick={() => props.updateSettings({ ...props.settings, hideinactiveUsers: !props.settings.hideinactiveUsers })}
                            isActive={props.settings.hideinactiveUsers}
                        />
                    </div>
                    <div className='column'>
                        <IconToggleButton
                            Icon={DocumentSearch20Regular}
                            text="multisökning"
                            onClick={() => props.updateSettings({ ...props.settings, mulitSearch: !props.settings.mulitSearch })}
                            isActive={props.settings.mulitSearch}
                        />
                        <IconToggleButton
                            Icon={ZoomIn20Regular}
                            text="markera ändrade updrag"
                            onClick={() => {}}
                            isActive={true}
                        />
                    </div>
                    <div className='column'>
                        <IconToggleButton
                            Icon={ZoomIn20Regular}
                            text="visa kolumn filter"
                            onClick={() => props.updateSettings({ ...props.settings, showColumnFilter: !props.settings.showColumnFilter })}
                            isActive={props.settings.showColumnFilter}
                        />
                        <IconToggleButton
                            Icon={DocumentText20Regular}
                            text="rensa kolumn filter"
                            onClick={() => props.updateSettings({ ...props.settings, columnFilters: [] })}
                            isActive={false}
                        />
                    </div>
                    <div className='column'>
                        <IconToggleButton
                            Icon={ArrowCounterclockwise20Regular}
                            text="sortera live uppdrag"
                            onClick={() => props.updateSettings({ ...props.settings, sortLiveMissions: !props.settings.sortLiveMissions })}
                            isActive={props.settings.sortLiveMissions}
                        />
                        <IconToggleButton
                            Icon={Folder20Regular}
                            text="öppna order (CTR + O)"
                            onClick={() => openNewTab(props.selectedItem)}
                            isActive={false}
                        />
                    </div>
                </div>
            </div>
        </div>
    );

}

export default General;