import { BookSearch24Regular } from "@fluentui/react-icons";
import { DateTime } from "luxon";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { getOrderInfo } from "../Services/lookupService";
import AddressInfo from "../components/OrderView/General/AddressInfo";
import CustomerInfo from "../components/OrderView/General/CustomerForm";
import KolliInfo from "../components/OrderView/General/KolliInfo";
import PodInfo from "../components/OrderView/General/PodInfo";
import ServiceList from "../components/OrderView/General/ServiceList";
import MissionInfo from "../components/OrderView/Mission/MissionInfo";
import { DotLoadingIndicator } from "../components/loadingIndicator";
import "../styles/OrderStyles.css";



const OrderPage = () => {

    const topMenuItems: string[] = ["Allmänt", "Uppdrag", "Logg/POD/aviseringar", "Krediteringar/Skador/Filer", "CheckList/Farligt gods", "OrderLogg"];

    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const data: string | null = JSON.parse(queryParams.get("data") || "null");

    const [orderData, setOrderData] = useState<any | null | undefined>(undefined);
    const [events, setEvents] = useState<any[]>([]);

    const [selectedTopTab, setSelectedTopTab] = useState<number>(0);

    const hasFetchedData = useRef<boolean>(false);

    const _getOrderData = async (archive: boolean = false) => {
        if (!data) return;

        const start = DateTime.now();

        try {
            if (orderData === null) setOrderData(undefined);

            const response = await getOrderInfo(data, archive);

            console.log("result", response.orderInfo, response.events.length, `(${start.diffNow().milliseconds * -1}ms)`);

            if (response) {
                setOrderData(response.orderInfo);
                setEvents(response.events);
            }
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        if (hasFetchedData.current) return;
        hasFetchedData.current = true;

        _getOrderData();
    })


    const topTabs = topMenuItems.map((value, index) => (
        <div
            key={index}
            className={`Tab ${index === selectedTopTab ? 'selected' : 'notSelected'}`}
        >
            <div onClick={() => setSelectedTopTab(index)}>{value}</div>
        </div>
    ));

    const getPage = () => {
        switch (selectedTopTab) {
            case 0: 
                return (
                    <div>
                        <CustomerInfo orderData={orderData} />
                        <AddressInfo orderData={orderData} />
                        <ServiceList orderData={orderData} />
                        <KolliInfo orderData={orderData} events={events} />
                        <PodInfo orderData={orderData} events={events.filter((event) => event.function == "unload" || event.function == "loading")}/>
                    </div>
                );
            case 1: return (
                <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                    <MissionInfo orderData={orderData} />
                    {/* <RouteInfo orderData={orderData} /> */}
                </div>
            );
            case 2: return (
                <div></div>
            );
            case 3: return (
                <div></div>
            );
            case 4: return (
                <div></div>
            );
            case 5: return (
                <div></div>
            );
        }
    }


    return (
        <div style={{ width: "100%", height: "100%" }}>
            {orderData === undefined ? (
                <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <DotLoadingIndicator />
                </div>
            )
            : (orderData == null ? 
                <div style={{ height: "100%", width: "100%", alignContent: "center" }}>
                    <center>
                        <div style={{ fontSize: "2rem" }}>Order not found</div>
                        <button onClick={() => _getOrderData(true)} style={{ display: "flex", marginTop: "1rem", padding: "0.6rem", alignItems: "center", justifyContent: "center", textAlign: "center", paddingInline: "1.2rem" }}>
                            <BookSearch24Regular />
                            <div style={{ fontSize: "1.15rem", marginLeft: "0.5rem" }}>Search archive</div>
                        </button>
                    </center>
                </div>
             : <div className="app-container">
                    <div style={{ marginLeft: 4 }}>
                        <div>{topTabs}</div>
                    </div>
                    <div className="contentWrapper">
                        {getPage()}
                    </div>
                </div>
            )}
        </div>
    );
}


export default OrderPage;