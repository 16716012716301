import { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import "../styles/modal.css";

export interface ImessgeModalProps {
  onClose: () => void;
  onconfirm: (value: string | undefined) => void;
  title: string;
}

const MessageModal = (props: ImessgeModalProps) => {
  const [text, setText] = useState<string>("");
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    setTimeout(() => {
        textareaRef.current?.focus();
    }, 125);
  }, []);

  return ReactDOM.createPortal(
    <div className="modal-overlay">
      <div className="modal-content">
        {props.title && (
          <div className="modal-header">
            <h2>{props.title}</h2>
            <button className="modal-close-button" onClick={() => props.onClose()}>
              &times;
            </button>
          </div>
        )}
        <div className="modal-body">
          <textarea
            ref={textareaRef}
            className="modal-textarea"
            onChange={(event) => setText(event.target.value)}
          />
        </div>
        <div className="modal-footer">
          <button className="modalButton" onClick={() => props.onconfirm(undefined)}>
            {"Avbryt (escape)"}
          </button>
          <button className="modalButton" onClick={() => props.onconfirm(text)}>
            {"Skicka"}
          </button>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default MessageModal;