
import "../styles/loading.css";



export const DotLoadingIndicator = () => {
  return (
    <div className="loading-dots">
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};



export const SpinLoadingIndicator = () => {
  return (
    <div className="spinner"></div>
  );
}