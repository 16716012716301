import { Filter20Filled } from '@fluentui/react-icons';
import React, { useEffect, useRef, useState } from 'react';
import { columnTypeMap, columnTypes } from '../helpers/TranslationMaps';

import "../styles/styles.css";

export const enum searchType {
  contains,
  equals,
  notEquals,
  startsWith,
  endsWith,
}

export interface IColumnMapping {
  column: string;
  onSearchChange: (search: IColumnSearch) => void;
  onSortChange: (sorting: IColumnSorting) => void;
}

export interface IColumnSearch {
  column: string;
  search: string;
  type: searchType;
}

export interface IColumnSorting {
  column: string;
  descending: boolean;
}

const ColumnSorting = (props: IColumnMapping): JSX.Element => {
  const { column, onSearchChange, onSortChange } = props;
  const type = columnTypeMap[column];

  if (!type) {
    console.error(`Column "${column}" not found in columnTypeMap`);
  }

  const [filterValue, setFilterValue] = useState<string>("");
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [currentSearchType, setCurrentSearchType] = useState<searchType>(searchType.contains);
  const menuRef = useRef<HTMLDivElement>(null);

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setFilterValue(value);
    onSearchChange({
      column,
      search: value.toLowerCase(),
      type: currentSearchType,
    });
  };

  const handleIconClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleSortOptionClick = (descending: boolean) => {
    onSortChange({ column, descending });
    setIsMenuOpen(false);
  };

  const handleSearchTypeChange = (type: searchType) => {
    setCurrentSearchType(type);
    onSearchChange({
      column,
      search: filterValue.toLowerCase(),
      type,
    });
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        isMenuOpen &&
        menuRef.current &&
        !menuRef.current.contains(event.target as Node)
      ) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMenuOpen]);

  const renderSortOptions = () => {
    let sortOptions: Array<{ label: string; descending: boolean }> = [];
    let searchTypes: Array<{ label: string; value: searchType }> = [];

    switch (type) {
      case columnTypes.fulldate:
      case columnTypes.endDate:
      case columnTypes.startDate:
        sortOptions = [
          { label: 'Newest First', descending: true },
          { label: 'Oldest First', descending: false },
        ];
        break;
      case columnTypes.number:
        sortOptions = [
          { label: 'Descending', descending: true },
          { label: 'Ascending', descending: false },
        ];
        break;
      case columnTypes.text:
        sortOptions = [
          { label: 'Z to A', descending: true },
          { label: 'A to Z', descending: false },
        ];
        searchTypes = [
          { label: 'Contains', value: searchType.contains },
          { label: 'Equals', value: searchType.equals },
          { label: 'Not Equals', value: searchType.notEquals },
          { label: 'Starts With', value: searchType.startsWith },
          { label: 'Ends With', value: searchType.endsWith },
        ];
        break;
      default:
        sortOptions = [
          { label: 'Descending', descending: true },
          { label: 'Ascending', descending: false },
        ];
        searchTypes = [
          { label: 'Contains', value: searchType.contains },
          { label: 'Equals', value: searchType.equals },
          { label: 'Not Equals', value: searchType.notEquals },
          { label: 'Starts With', value: searchType.startsWith },
          { label: 'Ends With', value: searchType.endsWith },
        ];
    }

    return (
      <div className="menu" ref={menuRef}>
        <div className="menu-section">
          <div className="menu-title">Sort Options</div>
          {sortOptions.map((option) => (
            <div
              key={option.label}
              className="menu-item"
              onClick={() => handleSortOptionClick(option.descending)}
            >
              {option.label}
            </div>
          ))}
        </div>
        {searchTypes.length > 0 && (
          <div className="menu-section">
            <div className="menu-title">Search Type</div>
            {searchTypes.map((typeOption) => (
              <div
                key={typeOption.label}
                className="menu-item"
                onClick={() => handleSearchTypeChange(typeOption.value)}
              >
                {typeOption.label}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <th className="column-sorting">
      <input
        type="text"
        value={filterValue}
        onChange={handleFilterChange}
        placeholder=""
      />
      <div className="icon-container" onClick={handleIconClick}>
        <Filter20Filled />
        {isMenuOpen && renderSortOptions()}
      </div>
    </th>
  );
};

export default ColumnSorting;