import { calculateCostSummary } from "../../../helpers/CostSummary";
import CostDisplay from "../../costDisplay";
import TimeLimitTable from "./TimeLimitTable";




interface ITimeLimitProps {
    items: any[] | undefined;
    selectedItem: string | undefined;
    filters: string[];
    onSingleTap: (item: any) => void;
}


const TimeLimit = (props: ITimeLimitProps) => {

    const createdStats = calculateCostSummary(props.items || []);

    return (
        <div style={{ border: "solid 0.0625rem black", padding: 5 }}>
            <div className="column">
                <div className="expand doubleExpanded">
                    <div className="spacedRow" style={{ padding: "0px" }}>
                        <div className="sectionTitle">Tidspassning</div>
                        <CostDisplay summary={createdStats} />
                    </div>
                    <TimeLimitTable items={props.items} selectedItem={props.selectedItem} onSingleTap={props.onSingleTap} filters={props.filters}></TimeLimitTable>
                </div>
            </div>
        </div>
    )
}


export default TimeLimit;