








export const enum ActionType {
    AUTH_ADD,
    AUTH_CLEAR,

    CLIENT_OPTIONS_ADD,
    CLIENT_OPTIONS_CLEAR,

    MESSAGE_ADD,
    MESSAGE_CLEAR,

    PASSIVE_ADD,
    PASSIVE_CLEAR,

    DATA_ADD,
    DATA_CLEAR,
}