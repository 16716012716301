import {
  Toast,
  ToastBody,
  ToastFooter,
  ToastTitle,
} from "@fluentui/react-components";

interface ISnackbarProps {
  title: string;
  subtitle?: string;
  footer?: JSX.Element;
}

const Snackbar = (props: ISnackbarProps) => {
  return (
    <Toast>
      <ToastTitle>{props.title}</ToastTitle>
      {props.subtitle && <ToastBody>{props.subtitle}</ToastBody>}
      {props.footer && <ToastFooter>{props.footer}</ToastFooter>}
    </Toast>
  );
};

export default Snackbar;
